import React, { useContext } from 'react'
import { Context } from '../../index'
import { GeoPicker } from '../GeoPicker'
import { User } from '../User'
import { PaneMenu } from '../PaneMenu'
import PhoneRussiaLink from '../PhoneRussiaLink'

import styles from './pane.module.scss'

export function Pane({ isCustomStore }) {
  const { user } = useContext(Context)
  const isLogged = !!user.name

  return (
    <div className={styles.pane}>
      <div className={styles.left}>
        <GeoPicker />
        <PhoneRussiaLink inHeader />
      </div>
      <ul className={styles.menu}>
        <PaneMenu isCustomStore={isCustomStore}/>
        {isLogged ? (
          <li>
            <User />
          </li>
        ) : (
          <>
            <li>
              <a href="/sign_in">Войти</a>
            </li>
            <li>
              <a href="/sign_up">Регистрация</a>
            </li>
          </>
        )}
      </ul>
    </div>
  )
}
