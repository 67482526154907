import React, {useState, useRef} from "react";
import loadable from '@loadable/component'
import classnames from "classnames";
import {Menus} from "../Menus";
import {Cart} from "../Cart";
import {HeaderWrapper} from "../HeaderWrapper";
import {useOnClickOutside} from "../../../../utils/use-on-click-outside";

import styles from "./header.module.scss";
import logo from "../../../../assets/images/logo.svg";
import MenuIcon from "../../../../icons/MenuIcon";
import SearchIcon from "../../../common/Icons/SearchIcon";
import CloseIcon from "../../../common/Icons/CloseIcon";
import GenericButton from "../../../common/GenericButton";

const Search = loadable(() => import('../Search'))

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const containerRef = useRef(null);

  useOnClickOutside(containerRef, () => setIsMenuOpen(false));

  return (
    <HeaderWrapper className={searchMode && styles.searchMode}>
      <div className={classnames(styles.container, styles.headerContainer)}>
        <div className={styles.left}>
          <GenericButton
            className={classnames(styles.menuButton, {
              [styles.active]: isMenuOpen
            })}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            icon
          >
            <MenuIcon />
          </GenericButton>
          <a href="/" className={styles.logo}>
            <img loading="lazy" src={logo} alt="Printio" />
          </a>
          <Menus
            searchMode={searchMode}
            isOpen={isMenuOpen}
            close={() => setIsMenuOpen(false)}
          />
          {searchMode && <Search />}
        </div>
        <div className={styles.right}>
          {!searchMode ? (
            <GenericButton
              onClick={() => setSearchMode(true)}
              className={styles.searchButton}
              icon
            >
              <SearchIcon />
            </GenericButton>
          ) : (
            <GenericButton
              onClick={() => setSearchMode(false)}
              className={styles.closeSearchButton}
              icon
            >
              <CloseIcon />
            </GenericButton>
          )}
          <Cart className={styles.cart} />
        </div>
        <div className={styles.headerLine}></div>
      </div>
    </HeaderWrapper>
  );
}
